import React , {useState} from "react";
import {MdFacebook} from 'react-icons/md'
import {BsTwitter,BsYoutube,BsBag} from 'react-icons/bs'
import {Link, useNavigate, useParams} from 'react-router-dom'
import * as Scroll from 'react-scroll';
import Logo from "../../asserts/images/Logo.svg";

import {GiHamburgerMenu} from 'react-icons/gi'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    
   const navigate = useNavigate()

    const pathname = window.location.pathname 
    // const {path} = useParams()
    console.log(pathname);
const serviScroll =()=>{
if (pathname=="/") {
  return
}
else{
  navigate('/')
}

}
    const handLinkScroll = ()=>{
    console.log('first')
    if (pathname=="/"){
      
return
}else {
  navigate('/')

}

    }


  return (

  

    <nav
      class="
      flex flex-wrap
      items-center
      justify-between
      w-full
      py-4
      md:py-0
      sm:py-0
      xs:py-0
      px-4
      text-lg 
      
      
    "
    >
      <div>
        <Link className="text-white text-2xl font-medium" to="/">
        <img
          src={Logo}
          alt=""
         style={{width:"10rem"}}
        />
        </Link>
      </div>
      <div onClick={()=> setIsOpen(!isOpen)} className="xs:visible sm:invisible md:invisible lg:invisible   ">
        <p className="text-gray-400 text-lg"><GiHamburgerMenu/></p>
      </div>

      <div className={`${isOpen ? "block" : "hidden"} absolute
       top-12 right-3 xs:invisible sm:invisible md:invisible lg:visible`} id="menu">
        <ul>
          <li>
            <Link onClick={()=> setIsOpen(false)} class="md:p-4 
             px-4 pb-2 pt-2 block bg-gray-700 text-white  hover:text-purple-400" to="/">Home</Link>
          </li>
          <li>
          <Scroll.Link activeClass="active" className="md:p-4 py-2 px-4 block block bg-gray-700 text-white  hover:text-purple-400" spy={true} smooth={true} offset={-110} duration={600} onClick={serviScroll} to={pathname=='/'?'ser':''}   >
              Service
            </Scroll.Link>
          </li>
          <li>
          <Scroll.Link activeClass="active" className="md:p-4 py-2 px-4 block block bg-gray-700 text-white  hover:text-purple-400" 
          spy={true} smooth={true} offset={-110} duration={600} 
          to={pathname=='/'?'pro':''}
          onClick={handLinkScroll}
          >
              Project
            </Scroll.Link>
          </li>
        
          <li>

            <Link onClick={()=> setIsOpen(false)} class="md:p-4 px-4 pb-1 pt-2  
             block bg-gray-700 text-white  hover:text-purple-400" to="/contact-us">Contact</Link>
          </li>
        
         
        </ul>
      </div>

      <div class="hidden w-full md:flex md:items-center xs:invisible  md:w-auto" id="menu">
        <ul
          class="
          pt-4
          text-base text-white
          md:flex
          md:justify-around 
          md:pt-0"
        >
          <li>
            <Link class="md:p-4 py-2 block hover:text-purple-400" to="/">
            Home
            </Link>
          </li>
          <li>
          <Link  to="/" >
            <Scroll.Link onClick={serviScroll}
            activeClass="md:p-4 py-2 block hover:text-purple-400" className="md:p-4 py-2 block hover:text-purple-400" spy={true} smooth={true} offset={-110} duration={600} to={pathname=='/'?'ser':''}   >
              Service
            </Scroll.Link>
            </Link>
          </li>
          <li> 
            <Link  to="/" >
            <Scroll.Link  activeClass="md:p-4 py-2 block hover:text-purple-400" className="md:p-4 py-2 block hover:text-purple-400" spy={true} smooth={true} offset={-110} duration={600}   to={pathname=='/'?'pro':''}
          onClick={handLinkScroll}>
              Projects
            </Scroll.Link>
            
            </Link>
          
          </li>
          {/* <li>
            <Link class="md:p-4 py-2 block hover:text-purple-400" to="/members">
              People
            </Link>
          </li> */}
          <li>
            <Link
              class="md:p-4 py-2 block pl-4 hover:text-purple-400 text-white"
              to="/contact-us"
            >
              Contact
            </Link>
          </li>
          {/* <li>
            <Link class="md:p-4 mt-1 py-2 block hover:text-purple-400 text-white " to="#">
            <MdFacebook/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsTwitter/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsYoutube/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsBag/>
            </Link>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
