import React from "react";
// import heading from "../../asserts/images/heading.png";
import PeopleCard from "../../components/people-card/PeopleCard";
import detailImg from "../../asserts/images/detail.png";
import man4img from "../../asserts/images/man4.png";
import man3img from "../../asserts/images/man3.png"; 
import man2img from "../../asserts/images/man2.png"; 

const Details = () => {
  return (
    <div>
      <div  className="flex flex-col justify-center  items-center md:w-full sm:w-full xs:w-full">
        <img
          className="rounded-full details-img md:w-60 md:h-60 mt-6"
          src={detailImg}
          alt="Face"
        />
        <h1 className="text-center font-bold text-6xl text-white mt-4">Azah Anyeni</h1>
        <p className="text-center font-bold text-gray-400 text-4xl pb-10 mt-4">Designer</p>
      </div>
      <div className=" object-cover">
        <p className="text-white font-thin text-start lg:text-4xl sm:text-sm px-10 py-10">
          <p className="mb-4">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia,
          </p>{" "}
          <br />
          <p className="mb-4 font-thin">
            looked up one of the more obscure Latin words, consectetur, from a
            Lorem Ipsum passage, and going through the cites of the word in
            classical literature, discovered the undoubtable source. Lorem Ipsum
            comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
            Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
            BC. This book is a treatise on the theory of ethics, very popular
            during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum
            dolor sit amet..", comes from a line in section 1.10.32.{" "}
          </p>{" "}
          <br />
          The standard chunk of Lorem Ipsum used since the 1500s is reproduced
          below for those interested. Sections 1.10.32 and 1.10.33 from "de
          Finibus Bonorum et Malorum" by Cicero are also reproduced in their
          exact original form, accompanied by English versions from the 1914
          translation by H. Rackham.
        </p>
      </div>
      <p className="text-center text-white text-2xl mt-5 font-thin ">Other Members</p>
      <div className='container  my-12 mx-auto px-4 md:px-12 sm:px-3'>
        <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4">
            <PeopleCard img={man4img} maintext="Roelof Bekkenenks" subtext="React Developer"/>
            <PeopleCard img={man3img} maintext="Emma Oliveira" subtext="Illustrator"/>
            <PeopleCard img={man2img} maintext="Izabella Tabakova" subtext="Product Designer"/>
        </div>
        </div>
    </div>
  );
}

export default Details;
