import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './ServiceCard.css'

const ServiceCard = ({maintext,subtext,img,navigateTo}) => {
  const navigate = useNavigate();
  return (
    <  div onClick={()=> navigate(navigateTo)} className="my-1 service-card md:flex-row md:max-w-xl lg:px-6 md:px-6 sm:px-0 xs:px-0">
    <article className="overflow-hidden bg-black rounded-sm shadow-lg ">
      <Link >
        <img
          alt="Placeholder"
          class="block h-auto w-full"
          src={img}
        />
      </Link>

      <header className="flex items-center justify-between leading-tight p-1 md:p-4">
        <h1 class="text-2xl flex flex-col">
          <Link  class="no-underline hover:underline text-gray-500 serviceDes" >
          {maintext}
          </Link>
          <Link   className="no-underline text-xs hover:underline sub text-gray-500 " >
          {subtext}
          </Link>
        </h1>
        <div className="flex flex-row items-center relative serviceBtn "  >
            <button  className="bg-slate-200 flex justify-center items-start -mr-10 absolute right-0 px-16 py-5 read-more">
              Read More <p className='text-2xl ml-2 mt-2'> <AiOutlineArrowRight/></p>
            </button>
        </div>
       
      </header>
    </article>
  </div>
  )
}

export default ServiceCard