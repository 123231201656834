import React from 'react'
import { Link } from "react-router-dom";
import './styles.css';

const HomeCard = ({mainText,first,second,third,forth,img}) => {
  return (
    <div className="my-1 w-full  lg:px-6  card-container ">
    <article class="overflow-hidden rounded-lg shadow-lg">
      <Link to="#">
        <img
          alt="Placeholder"
          class="block homeimg h-full w-full"
          src={img}
        />
      </Link>

        <h1 class="text-xl text-black font-semibold bg-slate-100 pt-2 px-4 flex flex-col"> {mainText}</h1>
      <header class="flex bg-slate-100 items-center justify-between leading-tight p-2 md:p-4">
        <ul className='list-disc px-5'>
            <li className='text-gray-500 py-2'>{first}</li>
            <li className='text-gray-500 py-2'> {second}</li>
            <li className='text-gray-500 py-2'> {third}</li>
            <li className='text-gray-500 pt-1'> {forth}</li>
        </ul>
      </header>
    </article>
  </div>
  )
}

export default HomeCard