import React, { useEffect } from "react";
import Card from "../../components/project-card/Card";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link, useLocation, useParams } from "react-router-dom";
import { animateScroll as Scroll } from "react-scroll";
// import heading from "../../asserts/images/heading.png";
import suply1img from "../../asserts/images/suply1.png";
import suply2img from "../../asserts/images/suply2.png";
import suply3img from "../../asserts/images/suply3.png";
import suply4img from "../../asserts/images/suply4.png";
import suply5img from "../../asserts/images/suply5.png";
import service1img from "../../asserts/images/service1.png";
import service2img from "../../asserts/images/service2.jpg";
import service3img from "../../asserts/images/service3.png";
import service4img from "../../asserts/images/service4.jpg";
import service5img from "../../asserts/images/service5.png";
import service6img from "../../asserts/images/service6.jpeg"
import halth1img from "../../asserts/images/halth1.jpeg";
import halth2img from "../../asserts/images/halth2.png";
import halth3img from "../../asserts/images/halth3.jpg";
import halth4img from "../../asserts/images/halth4.png";
import halth5img from "../../asserts/images/halth5.jpg";
import halth6img from "../../asserts/images/halth6.jpeg"
const Project = (props) => {
const {params} = props;
// console.log("======>" , props.navigation.getParams);
const { state } = useLocation();
console.log(state);
// useEffect  (()=>{
//   Scroll.scrollTo(500);

// },[])

  return (
    <div className="min-h-screen bg-neutral-800">
      <div className="flex flex-col pb-20 justify-center items-center md:w-full sm:w-full xs:w-full">
        <h1 className="text-white font-thin text-center text-6xl mt-16">
          {" "}
          {state.data} <br />
          {/* <p className="text-center mt-2">Innovation</p>  */}
        </h1>
        {/* <Link onClick={()=> scrollDown()} className="text-white text-5xl font-medium mt-20" to="#">
          <BsArrowDownCircle />
        </Link> */}
      </div>
      <div className="flex flex-col justify-center items-center "     >
      {state.data === "Supply Chain Innovation" && 
      <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
         
      Supply chain complicated with multiple factors globally remains a challenge for the coming decades. Our solutions are highly diverse for multiple industries. Our data procurement and curation process has provided supply optimizations to pharmaceutical, automobile and various other industries.
      </p>  }
      {state.data === "E-commerce innovation" && 
      <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
         Our solutions equip your business with advanced marketing, outreach, and warehouse management systems.
    
      </p>   }

        {state.data === "Health Care innovation" && 
      <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
         
         We provide technology solutions for health care providers and their partners to optimise patient outcomes, risk analytics, and patient experience.
    
      </p>   }

      {/* {state.data === "Pharmaceutical innovation" && 
      <p className="text-white font-thin text-center lg:text-4xl md:text-xl sm:text-sm xs:text-sm px-10 mt-24">
         

         We are highly equipped to provide both professionals and solutions for AI based drug discovery and process optimization.
    
      </p>   }
      {state.data === "Energy Sector innovation" && 
      <p className="text-white font-thin text-center lg:text-4xl md:text-xl sm:text-sm xs:text-sm px-10 mt-24">
         
         We pioneer at understanding the energy industry with peerless academic partnerships. We provide modern day technology (AI, Quantum, ML) for this complex industry
         
    
      </p>   }
      {state.data === "Finance Sector innovation" && 
      <p className="text-white font-thin text-center lg:text-4xl md:text-xl sm:text-sm xs:text-sm px-10 mt-24">
          We provide a range of solutions including fraud detection models, risk analysis models, data modernization models among other common finance industry solutions.
          
      </p>   } */}







      </div>
      <div className="container  my-12 mx-auto px-4 md:px-12">
  {/* <div className="flex flex-row   justify-around text-white font-thin  lg:text-4xl md:text-xl sm:text-sm xs:text-sm px-10 mt-24">
  {state.data=="E-commerce innovation"&& <h1 className="">
        Sku Rationalization
        lorem ispusm
        </h1> ||state.data == "Health Care innovation" && <h1>Member experience analytocs</h1> ||state.data == "Supply Chain Innovation" && <h1>Lot Data engineering</h1>}
        {state.data=="E-commerce innovation"&& <h1 >
      Demand predictions
        
        </h1> ||state.data == "Health Care innovation"  && <h1>  Risk profilling</h1>||state.data == "Supply Chain Innovation" && <h1>complex grid data integrations</h1> }

  </div> */}
        <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4 ">

            <Card  img={state.data=="E-commerce innovation"&& service1img ||state.data == "Health Care innovation" && halth1img ||state.data == "Supply Chain Innovation" && suply1img } maintext={state.data   == "Supply Chain Innovation" && "  Lot Data Engineering " ||state.data  =="E-commerce innovation" &&"SKU Rationalization"||state.data  =="Health Care innovation" &&"Member Experience Analytocs"} />
            <Card  img={state.data=="E-commerce innovation"&& service2img ||state.data == "Health Care innovation" && halth2img ||state.data == "Supply Chain Innovation" && suply2img } maintext={state.data   == "Supply Chain Innovation" && "  Complex Grid Data Integrations" ||state.data  =="E-commerce innovation" &&"Data Procurement "||state.data  =="Health Care innovation" &&"Risk Profilling "} />
            <Card img={state.data=="E-commerce innovation"&& service3img ||state.data == "Health Care innovation" && halth3img ||state.data == "Supply Chain Innovation" && suply3img } maintext={state.data   == "Supply Chain Innovation" && "  Operational Optimization " ||state.data  =="E-commerce innovation" &&"Data Procuremen"||state.data  =="Health Care innovation" &&"Digital Health "} />
            <Card  img={state.data=="E-commerce innovation"&& service4img ||state.data == "Health Care innovation" && halth4img ||state.data == "Supply Chain Innovation" && suply4img } maintext={state.data   == "Supply Chain Innovation" && " Performance Dashboards " ||state.data  =="E-commerce innovation" &&"ML Forecasting"||state.data  =="Health Care innovation" &&"Performance Evaluations "} />
            <Card  img={state.data=="E-commerce innovation"&& service5img ||state.data == "Health Care innovation" && halth5img ||state.data == "Supply Chain Innovation" && suply5img } maintext={state.data   == "Supply Chain Innovation" && " Material Innovation Lot  " ||state.data  =="E-commerce innovation" &&"Advanced Integration"||state.data  =="Health Care innovation" &&"AL Biomarkes "} />
            <Card  img={state.data=="E-commerce innovation"&& service6img ||state.data == "Health Care innovation" && halth6img ||state.data == "Supply Chain Innovation" && suply5img }  maintext={state.data   == "Supply Chain Innovation" && " AL Enabled  " ||state.data  =="E-commerce innovation" &&"Cloud Solution"||state.data  =="Health Care innovation" &&"Robotics  "}     />
 
        </div>
        
      </div>
    </div>
  );
};

export default Project;
