import React from "react";
import {MdFacebook} from 'react-icons/md'
import {BsTwitter,BsYoutube,BsBag} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import './Styles.css'

const Footer = () => {
  return (
    <div className="h-full mt-10">
    <div className=" flex flex-col justify-center items-center bg-neutral-800">
      <p className="text-white font-thin  text-center lg:text-2xl md:text-xl sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
        Ready to start exploring your next cloud based analytics project or a
        research project or a business transformation or looking for a career ?{" "}
      </p>
      <p className="text-gray-500 text-center font-thin lg:text-base md:text-base sm:text-sm xs:text-xs mt-12">
        Give us an opportunity to research, develop and implement for you
      </p>
      <div className="mb-10 white-btn" >
        <button className="bg-white text-black rounded-2xl font-normal py-2 px-4 mt-6 ">
        <p className="md:text-base sm:text-sm xs:text-xs">  We’re here to help and answer any question you might have. We look
          forward to hearing from you. <span className="text-black underline">Click here!</span></p>
        </button> 
      </div>
    </div>
    <footer
      class="
      flex flex-wrap
      items-center
      justify-between
      w-full
      py-4
      md:py-0
      px-4
      text-lg 
      bg-neutral-800
    "
    >
      <div>
        <Link className="text-white lg:text-lg md:text-lg sm:text-sm xs:text-xs copy font-thin" to="#">
        Copyright © 2022 Cca-llc Technologies. All Rights Reserved.
        </Link>
      </div>

      <div class="hidden w-full md:flex md:items-center lg:visible xs:invisible sm:invisible md:w-auto" id="menu">
        <ul
          class="
          pt-4
          text-base text-white
          md:flex
          md:justify-between 
          md:pt-0"
        >
          <li>
            <Link class="md:p-4 py-2 block hover:text-purple-400" to="/terms-conditions">
            Terms & Conditions
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block hover:text-purple-400" to="/privacy-policy">
            Privacy Policy
            </Link>
          </li>
          {/* <li>
            <Link class="md:p-4 mt-1 py-2 block hover:text-purple-400 text-white" to="#">
            <MdFacebook/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsTwitter/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsYoutube/>
            </Link>
          </li>
          <li>
            <Link class="md:p-4 py-2 block mt-1 hover:text-purple-400 text-white" to="#">
            <BsBag/>
            </Link>
          </li> */}
        </ul>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
