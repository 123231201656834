import React from 'react'
import {BsArrowDownCircle} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import { animateScroll as Scroll } from "react-scroll";
// import heading from "../../asserts/images/heading.png";
import PeopleCard from '../../components/people-card/PeopleCard';
import man4img from "../../asserts/images/man4.png";
import man3img from "../../asserts/images/man3.png"; 
import man2img from "../../asserts/images/man2.png"; 
import man1img from "../../asserts/images/man1.png";

const Members = () => {
  const scrollDown = () => {
    Scroll.scrollTo(500);
  }
  return (
    <div className="min-h-screen bg-neutral-800">
         <div  className="flex flex-col pb-20 justify-center items-center  md:w-full sm:w-full xs:w-full">
        <h1 className="text-white text-center font-thin text-6xl  mt-16">Lorem Ipsum is simply dummy <br />
        <p className="text-center mt-10"> text of the printing</p></h1>
        <Link onClick={()=> scrollDown()} className="text-white text-5xl font-medium mt-20" to="#">
          <BsArrowDownCircle/>
        </Link>
      </div>
        <div className="flex flex-col justify-center items-center">
        <p className="text-white font-thin text-center g:text-4xl md:text-lg sm:text-base xs:text-sm px-10 mt-5">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled
      </p>
      </div>
      <div className='container  my-12 mx-auto px-4 md:px-12'>
        <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4">
            <PeopleCard img={man1img} maintext="Azah Anyeni" subtext="Designer"/>
            <PeopleCard img={man4img} maintext="Roelof Bekkenenks" subtext="React Developer"/>
            <PeopleCard img={man3img} maintext="Emma Oliveira" subtext="Illustrator"/>
            <PeopleCard img={man2img} maintext="Izabella Tabakova" subtext="Product Designer"/>
 
        </div>
        </div>
    </div>
  )
}

export default Members