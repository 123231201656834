import React from 'react'
import {Link } from 'react-router-dom'


const Notfound = () => {
  return (
    <div className="bg-neutral-800">
        <div className="flex pb-20 flex-col justify-center items-center h-full md:w-full sm:w-full xs:w-full">
            <h1 className="text-white text-center underline text-8xl font-light mt-36">Not Found</h1>
            <Link className="text-white text-2xl rounded bg-black p-3 font-medium mt-36 " to="/"> Go to home</Link>
        </div>
    </div>
  )
}

export default Notfound