import React from "react";
import { Link } from "react-router-dom";
import "./Project.css";

const Card = ({ maintext, img }) => {
  return (
    <>
      <div
      
        class="flex flex-col project-card lg:gap-32 md:gap-32 sm:gap-32 xs:gap-32 items-center   bg-white lg:m-4 rounded-lg  shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 w-2/5 "
      >
        <img
          class=" object-cover w-96 projectimg h-96  md:h-auto md:w-48 md:rounded-none   "
          src={img}
          alt=""
        />
        <div class=" p-1 leading-2">
          <p class="mb-3 text-center lg:mr-20 md:mr-20 sm:mr-10 xs:mr-10 font-normal text-black ">
            {maintext}
          </p>
        </div>
      </div>
    </>
  );
};

export default Card;
