import React from "react";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { animateScroll as Scroll } from "react-scroll";
import Card from "../../components/member-card/Card";
import ServiceCard from "../../components/service-card/ServiceCard";
// import heading from "../../asserts/images/heading.png";
import cloudimg from "../../asserts/images/cloud.jpg";
import Rectangle10img from "../../asserts/images/Rectangle10.png";
import Rectangle11img from "../../asserts/images/Rectangle11.png";
import Rectangle12img from "../../asserts/images/Rectangle12.png";
import Rectangle13img from "../../asserts/images/Rectangle13.png";
import Rectangle14img from "../../asserts/images/Rectangle14.png";
import Rectangle15img from "../../asserts/images/Rectangle15.png";
import Rectangle9img from "../../asserts/images/Rectangle7.png";
import Rectangle8img from "../../asserts/images/Rectangle8.png";

const Service1 = () => {
  const scrollDown = () => {
    Scroll.scrollTo(500);
  }
  return (
    <div>
      <div
        className="flex pb-20 flex-col justify-center items-center md:w-full sm:w-full xs:w-full"
      >
        <h1 className="text-white text-center font-thin text-5xl mt-16">
        A cloud strategy is the key to business success.
          
        </h1>
        <Link onClick={()=> scrollDown()} className="text-white text-5xl  mt-20" to="#">
          <BsArrowDownCircle />
        </Link>
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
        We provide multi-cloud strategies for high redundancy, resilience, and cost efficiency.
Solution

        </p>
      </div>
      <div class="relative ml-30 mt-10 px-20 h-80 overflow-hidden pb-8">
        <img
          src={cloudimg}
          alt="Avatar"
          class="object-cover w-full h-full"
        />
        {/* <div class="absolute lg:left-[25rem] top-14 mt-10 w-full py-3 bottom-0 inset-x-0 font-extrabold max-width   text-white lg:text-4xl md:text-lg sm:text-base xs:text-sm text-center leading-4 ">
          Academic Collaborations
        </div> */}
      </div>

    </div>
  );
};

export default Service1;
