import React from "react";
import { Link } from "react-router-dom";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './People.css';


const PeopleCard = ({maintext,subtext,img}) => {
  return (
      <div class="my-1 px-1 w-full lg:mx-2   member-card lg:px-6">
        <article class="overflow-hidden bg-black rounded-sm shadow-lg">
          <Link to="#">
            <img
              alt="Placeholder"
              class="block h-auto w-full"
              src={img}
            />
          </Link>

          <header class="flex  items-center justify-between leading-tight p-1 md:p-4">
            <h1 class=" flex flex-col text-sm memberTxt">
              <Link class="no-underline hover:underline text-white" to="#">
                {maintext}
              </Link>
              <Link className="no-underline text-xs hover:underline text-gray-500 " to="#">
          {subtext}
          </Link>
            </h1>
            <div className="flex items-center relative memberBtn">
            <Link to="/details" class="btnP text-grey-darker absolute px-7 py-4 -ml-16 bg-slate-200 text-4xl"><AiOutlineArrowRight/></Link>
            </div>
           
          </header>
        </article>
      </div>
  );
};

export default PeopleCard;