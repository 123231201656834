import './App.css';
import {Routes,Route} from "react-router-dom" 
import Home from './pages/home/Home';
import ContactPage from './pages/contact-us/ContactPage';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TermsConditions from './pages/terms-conditions/TermsConditions';
import Details from './pages/details/Details';
import Members from './pages/members/Members';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Project from './pages/projects/Project';
import Service from './pages/service/Service';
import Service1 from './pages/service/Service1';
import Service2 from './pages/service/Service2';
import Notfound from './pages/not-found/Notfound';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  return (
    <div className='container max-width'>
      <Navbar/>
      <ScrollToTop>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/contact-us" element={<ContactPage/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms-conditions" element={<TermsConditions/>} />
      <Route path="/details" element={<Details/>} />
      <Route path="/members" element={<Members/>} />
      <Route path="/projects" element={<Project/>} />
      <Route path="/services" element={<Service/>} />
      <Route path="/services1" element={<Service1/>} />
      <Route path="/services2" element={<Service2/>} />
      <Route path="/not-found" element={<Notfound/>} />
    </Routes>
    </ScrollToTop>
      <Footer/>
    </div>
  );
}

export default App;
