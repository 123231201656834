import React from "react";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { animateScroll as Scroll } from "react-scroll";
import HomeCard from "../../components/home-card/HomeCard";
import Card from "../../components/member-card/Card";
import ServiceCard from "../../components/service-card/ServiceCard";
// import heading from "../../asserts/images/heading.png";
import Rectangle2img from "../../asserts/images/Rectangle2.png";
import Rectangle3img from "../../asserts/images/Rectangle3.png";
import Rectangle4img from "../../asserts/images/Rectangle4.png";
import Rectangle7img from "../../asserts/images/Rectangle7.png";
import Rectangle8img from "../../asserts/images/Rectangle8.png";
import Rectangle9img from "../../asserts/images/Rectangle9.png";
import Rectangle10img from "../../asserts/images/Rectangle10.png";
import Rectangle11img from "../../asserts/images/Rectangle11.png";
import Rectangle12img from "../../asserts/images/Rectangle12.png";
// import Rectangle13img from "../../asserts/images/Rectangle13.png";
// import Rectangle14img from "../../asserts/images/Rectangle14.png";
// import Rectangle15img from "../../asserts/images/Rectangle15.png";


const Home = () => {
  const scrollDown = () => {
    Scroll.scrollTo(500);
  }
  return (
    <div>
      <div  className="flex pb-20 flex-col justify-center items-center  md:w-full sm:w-full xs:w-full ">
        <h1 className="text-white text-center font-bold-center  text-6xl mt-16 ">
           RESEARCH DRIVEN IT SOLUTIONS <br />
          {/* <p className="text-center mt-10 font-bold-center">with research drive it solutions and IT</p> */}
        </h1>
        {/* <Link onClick={()=> scrollDown()} className="text-white text-5xl  mt-20 " to="#"> */}
          {/* <BsArrowDownCircle /> */}
        {/* </Link> */}
      </div>
      <div className="flex flex-col justify-center items-center">
      <h1 className=" text-white font-bold-center max-width  lg:text-4xl md:text-lg sm:text-base xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-5 mt-8">
         Who We Are
         
      {/* <hr/> */}
        </h1>
        <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
          We are a group of individuals with scientific research experience in
          academia and industry. We provide disrupting technologies for next
          generation AI based telco, e-commerce, supply chain, healthcare,
          transportation, climate change, process optimization solutions.
        </p>
      </div>
      <div className="my-12 mt-28  mx-auto px-4 pb-16 md:px-12 ">
      <h1 className="text-white font-bold-center text-center max-width  lg:text-4xl md:text-lg sm:text-base xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0  mt-15  ">
          Our Niche
        </h1>
        <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4 mt-6">
            <HomeCard img={Rectangle2img} mainText="Solutions" first="Technlogy driven" second="Ai/ml driven" third="R&D optimized" forth="Cost efficient" />
            <HomeCard img={Rectangle3img}  mainText="Strategies:" first="Research driven  " second="Collaborative partnerships " third="Intellutual property " forth="Susbtainable" />
            <HomeCard img={Rectangle4img}  mainText="Connections" first="Industry partners" second="Academic collaborations" third="Incubator relationships" forth="Global partners" />
        </div>
      </div>
      <div id="ser"  className="flex flex-col justify-center items-center">
        <h1 className="text-white font-bold-center text-center max-width  lg:text-4xl md:text-lg sm:text-base xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-5 mt-8">
          Services
        </h1>
        <div className="container  my-12 mx-auto px-4 md:px-12 sm:px-5 xs:px-0">
          <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4">
              <ServiceCard img={Rectangle7img}  maintext="Innovation as a Solution ( IAAS )" subtext="Innovation lies in heart of business success" navigateTo={'/services'}/>
              <ServiceCard img={Rectangle8img}  maintext="Cloud as a Solution ( CAAS )" subtext="Cloud strategy is the key to business success " navigateTo={'/services1'}/>
              <ServiceCard img={Rectangle9img}  maintext="Training as a Solution ( TAAS )" subtext="Advanced post doctorate level faculty " navigateTo={'/services2'}/>
           
          </div>
        </div>
      </div>
      <div id="pro" className="flex flex-col justify-center items-center ">
        <h1 className="text-white font-bold-center text-center max-width  lg:text-4xl md:text-lg sm:text-base xs:text-sm px-10 mb-10 mt-10">
          Projects
        </h1>
        <p className="text-white font-normal text-center  lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
          We have provided consulting services to leaders in telecommunication,
          E-commerce, Healthcare, IT development, Supply Chain, Pharmaceutical,
          Academic, Energy Storage, Federal contracting industries. We provided
          patentable know-how to our clients that have led to business
          transformations.
        </p>
      {/* <p> */}
      {/* <div className="flex flex-row mt-20">
          <Link className="text-white lg:p-3 md:p-3 sm:p-1 xs:p-0 text-center rounded-l-3xl  bg-black lg:text-xl md:text-lg sm:text-xs tabs " to="#">
            Innovation as a solution
          </Link>
          <Link className="text-black lg:p-3 md:p-3 sm:p-1 xs:p-0 text-center  bg-white lg:text-xl md:text-lg sm:text-xs tabs" to="#">
            Training as a solution
          </Link>
          <Link className="text-black lg:p-3 md:p-3 sm:p-1 xs:p-0 text-center  rounded-r-3xl  bg-white  lg:text-xl md:text-lg sm:text-xs tabs" to="#">
          Cloud as a solution
          </Link>
        </div>
      </p> */}

        <div className="container  my-12 mx-auto lg:px-4 md:px-12 sm:px-0 xs:px-0 ">
          <div className="flex flex-wrap justify-center font-normal  lg:text-lg items-center -mx-1 lg:-mx-4">
          <Card img={Rectangle10img}  maintext="Supply Chain Innovation" subtext="" />
            <Card img={Rectangle11img} maintext="E-commerce innovation" subtext="" />
            <Card img={Rectangle12img} maintext="Health Care innovation" subtext="" />
            {/* <Card img={Rectangle13img} maintext="Pharmaceutical innovation" subtext="" />
            <Card img={Rectangle14img} maintext="Energy Sector innovation" subtext="" />
            <Card img={Rectangle15img} maintext="Finance Sector innovation" subtext="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
