import React, { useState } from "react";
import  { useRef } from "react";
import emailjs from "emailjs-com";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from 'axios';
import { animateScroll as Scroll } from "react-scroll";
// import heading from "../../asserts/images/heading.png";
import "./Contact.css"

const ContactPage = () => {
  const scrollDown = () => {
    Scroll.scrollTo(500);
  }

  function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
}

  // const url=""
  // const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if (  formvalue.user_email && formvalue.user_subject && formvalue.message) {
      if(ValidateEmail(formvalue.user_email)){
        emailjs.send('service_panioya', 'template_bs7660j', formvalue, 'UAhv0uDJ0_vMdG9aS')
      .then(
        (result) => {
          console.log(result.text);
        },
       (error) => {
          console.log(error.text);
      }
      );
      
      };
      }
    }

  const [formvalue ,setFormvalue] =useState({user_email:"",user_subject:"",message:""});
  const handelchange =(e)=>{
const newdata= {...formvalue}
newdata[e.target.name]=e.target.value
    // const{email, value}=e.target
    setFormvalue(newdata)
    console.log(formvalue)
  }

//   const submit=(e)=>{
//     console.log("first")
// e.preventDefault();
// axios.post(url,{
//   email:formvalue.email,
//   subject:formvalue.subject,
//   message:formvalue.message

// }).then(res => {
//   console.log(res.formvalue)
// })

  // }
  return (
    <div className=" bg-neutral-800" >
      <div className="flex pb-20 flex-col justify-center items-center  md:w-full sm:w-full xs:w-full">
        <h1 className="text-white font-thin text-center text-6xl mt-16">
          Contact Us
        </h1>
        <Link onClick={()=> scrollDown()} className="text-white text-5xl  font-medium mt-20" to="#">
          <BsArrowDownCircle />
        </Link>
      </div>
      <h1 className="font-normal text-xl text-center pt-11 mb-10  text-white">Information</h1>
      <div className="infoWrapper">
        <div className="first">
          <div className="infoInput ">
            <p> phone </p>
            <input />
          </div>
          <div className="infoInput">
            <p> Email </p>
            <input type="email" name="company_email" />
          </div>
        </div>
        <div className="second">
          <div className="infoInput ">
            <p> global partner </p>
            <input />
          </div>
          <div className="infoInput">
            <p> global delevery </p>
            <input />
          </div>
        </div>
        <div className="infoInput lastone">
          <p> headquarter </p>
          <input />
        </div>
      </div>
      <h1 className="font-normal contact-text text-center text-xl  lg:pt-11 text-white">
        Contact information
      </h1>
      <form  >
      <div className="ContactInfoWrapper">
        <div className="second">
          <div className="infoInput ">
            <p> Your Email </p>
            <input type="email" name="user_email" value={formvalue.email} onChange={handelchange} autoComplete="off" required />
          </div>
          <div className="infoInput">
            <p> Subject </p>
            <input type="text" name="user_subject" value={formvalue.subject}  onChange={handelchange} autoComplete="off"required />
          </div>
        </div >
        <div className=" TextInput lastone">
          <p> your message </p>
          <textarea type="text" name="message" value={formvalue.message} onChange={handelchange} autoComplete="off" required/>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button onClick={sendEmail} className="bg-black text-white submit-btn font-bold py-2 px-4 ">
          Submit
        </button>
      </div>


      </form>
    </div>
  );
};

export default ContactPage;
