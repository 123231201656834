import React from 'react'
import { useEffect } from "react";
import { useLocation } from "react-router";
import { animateScroll as Scroll } from "react-scroll";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        Scroll.scrollToTop();
    }, [location]);

  return (
    <>{props.children}</>
  )
}

export default ScrollToTop