import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './MemberCard.css';

const Card = ({maintext,subtext,img}) => {
  const navigate = useNavigate();
  return (
      <div class="my-1 px-1 w-full lg:mx-10  member-card lg:px-6 small-card">
        <article class="overflow-hidden bg-black rounded-sm shadow-lg" onClick={()=> navigate("/projects" ,{ state:{data:maintext} } )}>
          <Link >
            <img
              alt="Placeholder"
              class="block h-auto w-full"
              src={img}
            />
          </Link>

          <header class="flex items-center justify-between leading-tight p-1 md:p-4">
            <h1  class="text-sm memberTxt title">
              <Link  className="no-underline hover:underline text-gray-500">
                
                {maintext} 
              </Link>
              <Link className="no-underline text-xs hover:underline text-gray-500 ">
          {subtext}
          </Link>
            </h1>
            <div  className="flex items-center relative memberBtn">
              <p class="text-grey-darker absolute px-7 py-2 -ml-16 bg-slate-200 text-4xl arrow"><AiOutlineArrowRight/></p>
            </div>
           
          </header>
        </article>
      </div>
  );
};

export default Card;