import React from "react";
import { BsArrowDownCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { animateScroll as Scroll } from "react-scroll";
import Card from "../../components/member-card/Card";
import ServiceCard from "../../components/service-card/ServiceCard";
// import heading from "../../asserts/images/heading.png";
import heatimg from "../../asserts/images/heat.jpg";
import Rectangle10img from "../../asserts/images/Rectangle10.png";
import Rectangle11img from "../../asserts/images/Rectangle11.png";
import Rectangle12img from "../../asserts/images/Rectangle12.png";
import Rectangle13img from "../../asserts/images/Rectangle13.png";
import Rectangle14img from "../../asserts/images/Rectangle14.png";
import Rectangle15img from "../../asserts/images/Rectangle15.png";
import Rectangle9img from "../../asserts/images/Rectangle7.png";
import Rectangle8img from "../../asserts/images/Rectangle8.png";

const Service = () => {
  const scrollDown = () => {
    Scroll.scrollTo(500);
  }
  return (
    <div>
      <div
        className="flex pb-20 flex-col justify-center items-center md:w-full sm:w-full xs:w-full"
      >
        <h1 className="text-white text-center font-thin text-5xl mt-16">
        The innovation lies at the heart of
         business success
        </h1>
        <Link onClick={()=> scrollDown()} className="text-white text-5xl  mt-20" to="#">
          <BsArrowDownCircle />
        </Link>
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-white font-normal text-center lg:text-lg md:text-lg sm:text-sm xs:text-sm lg:px-10  md:px-1 sm:px-0 xs:px-0 mb-10 mt-16">
        We provide innovative solutions to business problems that are hard to define. Our IAAS solutions walk your organisation’s team through our solution process to train and leverage the methodology.
        </p>
      </div>
      <div class="relative ml-30 mt-10 px-20 h-80 overflow-hidden pb-10">
        <img
          src={heatimg}
          alt="Avatar"
          class="object-cover w-full h-full"
        />
        {/* <div class="absolute lg:left-[25rem] top-14 mt-10 w-full py-3 bottom-0 inset-x-0 font-extrabold max-width   text-white lg:text-4xl md:text-lg sm:text-base xs:text-sm text-center leading-4 ">
          Academic Collaborations
        </div> */}
      </div>
      {/* <div className="flex flex-col justify-center items-center">
        <h1 className="text-white font-thin text-center lg:text-4xl md:text-lg sm:text-base xs:text-sm px-10 mt-10">
          Projects
        </h1>
        <p className="text-white font-thin text-center lg:text-4xl md:text-lg sm:text-base xs:text-sm px-10 mt-8">
          We have provided consulting services to leaders in telecommunication,
          E-commerce, Healthcare, IT development, Supply Chain, Pharmaceutical,
          Academic, Energy Storage, Federal contracting industries. We provided
          patentable know-how to our clients that have led to business
          transformations.
        </p>
        <div className="container  my-12 mx-auto px-4 md:px-12 ">
          <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4">
            <Card img={Rectangle10img}  maintext="Supply Chain Innovation" subtext="" />
            <Card img={Rectangle11img} maintext="E-commerce innovation" subtext="" />
            <Card img={Rectangle12img} maintext="Health Care innovation" subtext="" />
            <Card img={Rectangle13img} maintext="Pharmaceutical innovation" subtext="" />
            <Card img={Rectangle14img} maintext="Energy Sector innovation" subtext="" />
            <Card img={Rectangle15img} maintext="Finance Sector innovation" subtext="" />
          </div>
        </div>
      </div> */}
      {/* <div className="flex flex-col justify-center items-center">
        <h1 className="text-white font-thin text-center lg:text-4xl md:text-lg sm:text-base xs:text-sm px-10 mb-12 mt-5">
          Other Services
        </h1>
        <div className="container  lg:my-12  sm:my-12 xs:my-12 mx-auto lg:px-4 md:px-2 sm:px-0 xs:px-0 ">
          <div className="flex flex-wrap justify-center items-center -mx-1 lg:-mx-4">
          <ServiceCard img={Rectangle8img}  maintext="Cloud as a Solution ( CAAS )" subtext="Innovation lies in heart of business success " />
              <ServiceCard img={Rectangle9img}  maintext="Training as a Solution ( TAAS )" subtext="Innovation lies in heart of business success " />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Service;
